body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Inter, system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { font-family: "Inter", "system-ui"; overflow: hidden; }

@supports (font-variation-settings: normal) {
  html { font-family: "Inter var", "system-ui"; }
} */
 
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
 
    --primary: 342 97% 65;
    /* --primary: 76 100% 51%; */
    --primary-foreground: 0 0% 98%;
 
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
 
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
 
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
 
    --radius: 0.5rem;
  }
 
  [data-theme='dark'] {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
 
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
 
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;;
 
    --primary: 342 97% 65%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
 
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-black dark:bg-black;
  }
  img, svg, video, canvas, audio, iframe, embed, object {
    display: inline-grid;
  }
  img, video {
    max-width: fit-content;
    height: auto;
  }
  /* .bg-primary\/20 {
    background-color: hsl(var(--primary) / 0.2);
  }
  .bg-primary {
    background-color: hsl(var(--primary));
  } */
  input {
    outline: none;
  }
  input:focus{
    outline: none;
    box-shadow: none;
  }
  textarea {
    outline: none!important;
    resize: none;
  }
  textarea:focus {
    transform: none;
    outline: none!important;
    box-shadow: none;
  }
  /* ol, ul, menu {
    padding: revert;
    margin: auto;
  } */
  .virtual-text-area  {
    width: 800px;
  }
  .w-46 {
    width: 5rem;
  }
  .mobile-margin-top {
    margin-top: 0px;
  }
  @media (max-width: 980px) {
    .mobile-margin-top {
      margin-top: 45px;
    }
  }
  @media (max-width: 768px) {
    input {
      font-size: 16px; /* Prevents iOS from zooming in */
    }
  
    input:focus {
      font-size: 16px;
      transform: none;
      outline: none;
      box-shadow: none;
    }

    textarea {
      font-size: 16px; /* Prevents iOS from zooming in */
    }

    textarea:focus {
      transform: none;
      outline: none;
      box-shadow: none;
    }
  }
  .overflow-none {
    overflow: none !important;
  }
}